import { Card, CardActions, CardContent, CardMedia } from "@mui/material"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"

/*
<div className="w-[100px] h-[100px] rounded-full border-4 border-[#FF1D25] absolute z-30 top-52 left-10"></div>
            <div className="w-[200px] h-[200px] rounded-full border-4 border-[#931212] absolute z-30 lg:-top-10 top-0 lg:right-5 right-0"></div>
            <div className="w-[50px] h-[50px] rounded-full border-4 border-[#931212] absolute z-30 top-80 left-10"></div>
            <div className="w-[150px] h-[150px] rounded-full border-4 border-[#FF1D25] absolute z-30 top-[30rem] lg:left-[50%] left-6"></div>
            */

function Posts() {
    const [isLoading, setIsLoading] = useState(true)
    const [posts, setPosts] = useState(null)

    useEffect(() => {
        fetch("http://localhost:1337/api/posts?populate=*",
        {
            method: "GET",
            headers: {
                'Accept': 'Application/json'
            }
        })
        .then(res => res.json())
        .then(res => {
            setPosts(res.data)
            setIsLoading(false)
        })
    }, [])

    console.log(posts)

    return(
        <div className="w-full flex justify-center items-center lg:pt-0 pt-6 lg:pb-28 pb-8 relative">
            <div className="w-5/6 grid lg:grid-cols-2 grid-cols-1 gap-x-16 gap-y-8 z-50">
                {isLoading ? 'Loading..' : posts.slice(0).reverse().map(post => 
                    <div className="bg-[#EEEEEE] rounded-xl p-4 flex flex-col justify-between space-y-5">
                        <div className="flex lg:flex-row flex-col justify-between items-start lg:space-y-0 space-y-5">
                            <div className="lg:w-[50%] w-full">
                                {post.attributes.image.data.slice(0, 1).map(image => 
                                    <img src={"http://localhost:1337"+image.attributes.formats.medium.url} />
                                    )}
                            </div>
                            <div className="lg:w-[48%] w-full text-center">
                                <h3 className="text-3xl font-bold">{post.attributes.title}</h3>
                            </div>
                        </div>
                        <div>
                            <p className="line-clamp-4" dangerouslySetInnerHTML={{ __html: post.attributes.content }} />
                        </div>
                        <div className="flex flex-row justify-between items-center">
                            <p className="text-lg text-[#555555]">{post.attributes.date}</p>
                            <Link to={`/actualite/${post.id}`} className="bg-[#FF1D25] text-white py-2 px-5 rounded-full">Voir l'article</Link>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Posts