import BottomWave from "../../components/BottomWave"
import WaveHeader from "../../components/WaveHeader"
import BgWave1 from "../../assets/BgWave1.png"
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"

function Actualite() {
    const {id} = useParams()
    let [postState, setPostState] = useState(null)
    let [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        fetch(`http://localhost:1337/api/posts/${id}?populate=*`, 
        {
            method: "GET",
            headers: {
                'Accept': 'Application/json'
            }
        })
        
        .then(res => res.json())
        .then(res => {
            setPostState(res)
            setIsLoading(true)
        })
    })

    return(
        <div className="w-full flex flex-col justify-center items-center relative pb-32">
            <div className="w-full flex justify-center items-center relative max-h-[50vh]">
                {isLoading ? postState.data.attributes.image.data.slice(0, 1).map(image => 
                    <img src={"http://localhost:1337"+image.attributes.formats.medium.url} className="w-full max-h-[50vh] object-cover border-b-2 border-white" />
                ) : "Loading..."}
                <div className="absolute w-full h-full bg-[#931212]/40 top-0"></div>
                <WaveHeader />
                <div className="w-full h-1 absolute bg-white bottom-0"></div>
                <h1 className="absolute top-[15%] text-white font-bold lg:text-7xl text-4xl lg:w-2/3 w-11/12 text-center drop-shadow-xl">{isLoading ? postState.data.attributes.title : "Loading..."}</h1>
            </div>
            <div className="w-full flex flex-col items-center lg:pb-28 lg:pt-0 pt-16 relative">
                <div className="absolute lg:-top-20 top-0 lg:left-16 left-4 z-50">
                    <Link to="/actualites" className="bg-[#FF1D25] text-white py-2 px-5 rounded-full">Retour</Link>
                </div>
                <div className="w-5/6 flex flex-col items-start justify-start space-y-10 z-50">
                    {isLoading ? <p dangerouslySetInnerHTML={{ __html: postState.data.attributes.content }} /> : "Loading..."}
                    <div className="flex flex-col justify-center items-center w-full space-y-8">
                        <h2 className="lg:text-4xl text-3xl font-bold text-center">Galerie photo</h2>
                        <div className="w-full grid lg:grid-cols-3 grid-cols-1 gap-5">
                            {isLoading ? postState.data.attributes.image.data.map(image => 
                                <div className="flex justify-center items-center">
                                    <img src={"http://localhost:1337"+image.attributes.formats.medium.url} className="w-full max-h-[50vh] object-cover border-b-2 border-white" />
                                </div>
                            ) : "Loading..."}
                        </div>
                    </div>
                    <div className="w-full">
                        <p className="text-lg text-[#555555]">{isLoading ? (new Date(postState.data.attributes.date)).toLocaleDateString() : "Loading..."}</p>
                    </div>
                </div>
                <div className="w-[100px] h-[100px] rounded-full border-4 border-[#931212] absolute z-30 bottom-44 left-10"></div>
                <div className="w-[50px] h-[50px] rounded-full border-4 border-[#931212] absolute z-30 lg:-top-20 -top-4 right-16"></div>
                <div className="w-[100px] h-[100px] rounded-full border-4 border-[#FF1D25] absolute z-30 bottom-[25rem] right-36"></div>
            </div>
            <BottomWave />
        </div>
    )
}

export default Actualite